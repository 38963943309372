@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
/* ------------------- */
/* Custom properties   */
/* ------------------- */

:root {
  /* colors */

  /* shades */
  --clr-yellow-shade-050: 28, 70%, 52%, 1;
  --clr-yellow-shade-100: 28, 64%, 47%, 1;
  --clr-yellow-shade-200: 28, 64%, 42%, 1;
  --clr-yellow-shade-300: 28, 64%, 37%, 1;
  --clr-yellow-shade-400: 28, 64%, 31%, 1;
  --clr-yellow-shade-500: 29, 64%, 26%, 1;
  --clr-yellow-shade-600: 29, 64%, 21%, 1;
  --clr-yellow-shade-700: 29, 65%, 16%, 1;
  --clr-yellow-shade-800: 28, 63%, 11%, 1;
  --clr-yellow-shade-900: 28, 63%, 5%, 1;

  --clr-blue-shade-050: 244, 48%, 26%, 1;
  --clr-blue-shade-100: 243, 48%, 23%, 1;
  --clr-blue-shade-200: 244, 49%, 21%, 1;
  --clr-blue-shade-300: 244, 48%, 18%, 1;
  --clr-blue-shade-400: 245, 49%, 15%, 1;
  --clr-blue-shade-500: 244, 48%, 13%, 1;
  --clr-blue-shade-600: 242, 47%, 10%, 1;
  --clr-blue-shade-700: 243, 49%, 8%, 1;
  --clr-blue-shade-800: 245, 48%, 5%, 1;
  --clr-blue-shade-900: 249, 54%, 3%, 1;

  --clr-black-shade-100: 274, 6%, 23%, 1;
  --clr-black-shade-300: 276, 5%, 18%, 1;
  --clr-black-shade-800: 270, 8%, 5%, 1;
  --clr-black-shade-900: 240, 8%, 3%, 1;

  /* tints */
  --clr-yellow-tint-050: 28, 70%, 52%, 1;
  --clr-yellow-tint-100: 28, 71%, 57%, 1;
  --clr-yellow-tint-200: 28, 70%, 62%, 1;
  --clr-yellow-tint-300: 29, 71%, 67%, 1;
  --clr-yellow-tint-400: 28, 70%, 71%, 1;
  --clr-yellow-tint-500: 28, 70%, 76%, 1;
  --clr-yellow-tint-600: 29, 71%, 81%, 1;
  --clr-yellow-tint-700: 28, 70%, 86%, 1;
  --clr-yellow-tint-800: 28, 71%, 91%, 1;
  --clr-yellow-tint-900: 28, 68%, 95%, 1;
  --yellow-tint-90: #fbf2ea;

  --clr-blue-tint-050: 244, 48%, 26%, 1;
  --clr-blue-tint-100: 244, 34%, 33%, 1;
  --clr-blue-tint-200: 244, 25%, 41%, 1;
  --clr-blue-tint-300: 244, 18%, 48%, 1;
  --clr-blue-tint-400: 245, 17%, 55%, 1;
  --clr-blue-tint-500: 244, 17%, 63%, 1;
  --clr-blue-tint-600: 242, 17%, 70%, 1;
  --clr-blue-tint-700: 243, 17%, 78%, 1;
  --clr-blue-tint-800: 245, 17%, 85%, 1;
  --clr-blue-tint-900: 240, 16%, 93%, 1;

  --clr-black-tint-200: 270, 3%, 40%, 1;
  --clr-black-tint-800: 270, 3%, 85%, 1;
  --clr-black-tint-900: 0, 0%, 93%, 1;

  /* neutral */
  --clr-light: 0, 0%, 100%, 1;
  --clr-dark: 0, 0%, 0%, 1;
  --clr-gray: 270, 2%, 63%, 1;
  --clr-black: 0, 0%, 20%, 1;
  --clr-gray-50: 210, 40%, 98%, 1;

  /* secondary */
  --clr-success: 155, 68%, 30%, 1;
  --clr-error: 4, 89%, 44%, 1;

  /* specifics */
  --clr-border: 270, 5%, 33%, 1;

  /* font-sizes */
  /* --fs-900: 6.25rem; */
  --fs-950: 3.5rem;
  --fs-900: 2.5rem;
  --fs-800: 2rem;
  --fs-700: 1.75rem;
  --fs-600: 1.5rem;
  --fs-500: 1.25rem;
  --fs-400: 1.125rem;
  --fs-300: 1rem;
  --fs-200: 0.875rem;
  --fs-100: 0.75rem;

  /* font weight */
  --fw-700: 700;
  --fw-600: 600;
  --fw-500: 500;
  --fw-400: 400;

  --ff-sans-normal: "Montserrat";
  --ff-serif-normal: "Inter";
}

/* ------------------- */
/* Reset               */
/* ------------------- */

/* Box sizing */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset margins */

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure,
picture {
  margin: 0;
}

/* set body defaults */

body {
  min-height: 100vh;
  font-family: var(--ff-sans-normal), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: var(--fs-300);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  text-rendering: optimizeSpeed;
}

/* Make images easier to work with */

img,
picture {
  max-width: 100%;
  display: block;
}

/* forms defaults */

input,
button,
textarea,
select {
  font: inherit;
}

/* specifics */
a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

/* ------------------- */
/* Utility classes     */
/* ------------------- */

/* general */

.grid {
  display: grid;
  gap: var(--gap, 1rem);
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.flex-inline {
  display: inline-flex;
  gap: var(--gap, 1rem);
}

.block {
  display: block;
}

.no-display {
  display: none;
}

.container {
  max-width: 80rem;
  padding-inline: 2em;
  margin-inline: auto;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.flow > *:where(:not(:first-child)) {
  margin-top: var(--flow-space, 1rem);
}

.btn {
  min-height: 34px;
  margin-bottom: 0;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.btn-default {
  color: hsla(var(--clr-light));
  background-color: #022f1a;
  border-color: #022f1a;
}

.btn-default-ghost {
  color: #022f1a;
  background-color: transparent;
  border-color: #022f1a;
}

.btn-secondary {
  background-color: hsla(var(--clr-yellow-shade-050));
  color: hsla(var(--clr-dark));
  border-radius: 5px;
  padding: 0.7em 1.5em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

/* colors */
.bg-light {
  background-color: hsla(var(--clr-light));
}
.bg-gray {
  background-color: hsla(var(--clr-gray));
}
.bg-blue-dark-700 {
  background-color: hsla(var(--clr-blue-shade-700));
}
.bg-blue-tint-050 {
  background-color: #022f1a;
}
.bg-skeleton {
  background: linear-gradient(
    90deg,
    #f1efef -24.18%,
    #f9f8f8 50.26%,
    #e7e5e5 114.84%
  );
}

.text-black {
  color: hsla(var(--clr-black));
}
.text-light {
  color: hsla(var(--clr-light));
}
.text-error {
  color: hsla(var(--clr-error));
}
.text-blue-dark-050 {
  color: #0d6a3f;
}

.text-black-100 {
  color: hsla(var(--clr-black-shade-100));
}
.text-black-300 {
  color: hsla(var(--clr-black-shade-300));
}
.text-black-800 {
  color: hsla(var(--clr-black-shade-800));
}
.text-black-900 {
  color: hsla(var(--clr-black-shade-900));
}

.text-black-tint-900 {
  color: hsla(var(--clr-black-tint-900));
}

.text-blue-tint-100 {
  color: #022f1a;
}
.text-blue-tint-600 {
  color: hsla(var(--clr-blue-tint-600));
}

/* typography */
.ff-sans {
  font-family: var(--ff-sans);
}
.ff-serif {
  font-family: var(--ff-serif-normal);
}

.fs-900 {
  font-size: var(--fs-900);
}
.fs-800 {
  font-size: var(--fs-800);
}
.fs-700 {
  font-size: var(--fs-700);
}
.fs-600 {
  font-size: var(--fs-600);
}
.fs-500 {
  font-size: var(--fs-500);
}
.fs-400 {
  font-size: var(--fs-400);
}
.fs-300 {
  font-size: var(--fs-300);
}
.fs-200 {
  font-size: var(--fs-200);
}
.fs-100 {
  font-size: var(--fs-100);
}

.fw-700 {
  font-weight: var(--fw-700);
}
.fw-600 {
  font-weight: var(--fw-600);
}
.fw-500 {
  font-weight: var(--fw-500);
}
.fw-400 {
  font-weight: var(--fw-400);
}

.letter-spacing-1 {
  letter-spacing: 4.75px;
}
.letter-spacing-2 {
  letter-spacing: 2.7px;
}
.letter-spacing-3 {
  letter-spacing: 2.35px;
}

/* ----------- */
/* Components  */
/* ------------*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-dropdown-select-content {
  max-height: 100px;
  overflow-y: auto;
}
