.table-container {
  padding-bottom: 20px;
  min-height: calc(100vh - 470px);
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  height: 60vh;
}
table {
  border-collapse: collapse;
  width: 100%;
  padding: 0 3em;
  font-size: var(--fs-200);
  min-width: 400px;
  overflow-y: hidden;
}

thead tr {
  text-align: left;
}

th,
td {
  padding: 1.1em 3em;
}
td:first-child {
  text-decoration: underline;
  cursor: pointer;
}

tbody tr:nth-child(odd) {
  background-color: hsla(var(--clr-light));
}
tbody tr:hover {
  background-color: #e9e9ef;
}

.table-date p {
  width: 65px;
  text-align: center;
}
.table-date p:nth-child(2) {
  font-weight: 600;
  font-size: 14px;
}

.css-150fvlq-ReactDropdownSelect {
  /* z-index: 10 !important; */
  height: 40px !important;
  outline: none !important;
}
.css-puqxcc-DropDown {
  width: 100%;
  background: #fff;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
